export const STRINGS = {
  archive: 'Archive',
  ethalottoTotal: 'Total',
  winnersPayment: 'Winners Payment',
  addressCount: 'Address Count',
  pricePool: 'Monthly Prize Pool',
  selectMonth: 'Select month',
  ticketBooth: 'Ticket Booth',
  selectBuyin: 'Select buy-in',
  yourTickets: 'Your Tickets',
  ticketAmount: 'Amount ',
  ticketEntryDate: 'Date',
  ticketWalletAddress: 'Address ',
  eth: ' eth, ',
  ethalotto: 'Ethalotto',
  home: 'Home',
  buyTicket: 'Buy Ticket',
  enterLottery: 'Enter Lottery',
  controller: 'Controller',
  howDoesItWork: 'How does it work',
  termsAndConditions: 'Terms and conditions',
  oceanCleanup: 'Ocean cleanup',
  firstPlace: 'Winner',
  secondPlace: 'Runner-up',
  orderSummary: 'Order summary',
  selectedMonth: 'Selected Month',
  orderTotal: 'Order Total',
  ticketType: 'Ticket Type (count)',
  agreeTerms: 'I agree to terms and conditions',
  winnersDraw: 'Winners Draw',
  selectWinners: 'Select winners',
  sendWinners: 'Send winners to blockchain',
  position: 'Position',
  address: 'Address',
  totals: 'Totals',
  ethalottoAndOceanCleanup: 'Ethalotto & Ocean Cleanup',
  totalTickets: 'Total Tickets',
  totalEthereum: 'Total Ethereum',
  homeTitle: 'Play, Contribute, Win: Join Our Eco-Friendly Ethereum Lottery',
  homeSubTitle:
    'Support Environmental Causes and Win Ethereum in Our Monthly lotteries',
  filteredTotals: 'Filtered Totals',
  connectWallet: 'Connect to Metamask',
  chooseMonthAndType: 'Select Type and Month',
  winnerSelected: 'Await Draw',
  recievePrice: 'Receive Your Winnings',
  connectMetamask: 'Connect your Metamask wallet.',
  selectBuyinMonth:
    'Choose your lottery month and ticket type: Micro, Small, Mid, or Large. Each type offers different prices and participation levels.',
  lotteries:
    'Await lottery settlements on the 28th of each month. Boost your odds by buying multiple tickets.',
  transfers:
    'Earnings are sent to your participating wallet addresses within 48 hours. Winner addresses are displayed in the platform.',
  reforestationFirst:
    'Landscapes in Panamá are being increasingly impacted by urban expansion, highways, mining, and agricultural practices, such as cattle ranching, where fields are cleared of trees. These activities contribute to deforestation, threatening the delicate balance of our ecosystems and diminishing the natural beauty that defines our country.',
  reforestationSecond:
    'The loss of trees affects the local flora and fauna and disrupts the environment’s ability to sustain itself, leading to soil erosion, reduced biodiversity, and negative impacts on our climate.',
  reforestationThird:
    'Reforestation is a primary concern and a key reason why we are raising funds. By supporting our initiatives, you are contributing to the restoration of these vital areas, helping us to plant trees and revive the ecosystems that have been lost. Together, we can work towards a greener, more sustainable future for Panamá.',
  oceanCleanupFirst:
    'Panamá is rich in biodiversity, with a stunning array of plant and animal species both on land and in its waters. The country is home to 220 species of freshwater fish, 1,157 species of marine fish, and 3.4% of the world’s amphibian species, making it a truly unique and fascinating place to explore.',
  oceanCleanupSecond:
    'However, coastlines and rivers face growing threats from pollution, particularly from trash accumulating in rivers near urban areas. These environmental challenges put immense pressure on our natural resources and wildlife.',
  oceanCleanupThird:
    'This urgent situation is one of the main reasons for raising funds. By supporting our cause, you are helping us take action to protect Panamá’s coastlines and preserve its incredible biodiversity for future generations.',
  totalRaised: 'Total Raised',
  totalGivedBack: 'Total Payouts',
  termsHeader: 'TERMS AND CONDITIONS',
  termsIntroduction:
    'These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Ethalotto, accessible at ethalotto.org.',
  termsIntroductionSecond:
    'By using our Website, you accepted these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use our Website.',
  termsDescription:
    'Ethalotto consists of a monthly event organized by Ethalotto where participants can purchase tickets using Ethereum. The lottery will be held on the 28th of every month, and the winners will be determined through a random selection process, saving all tickets and winners on the Ethereum blockchain.',
  termsPriceDistribution:
    'The price distribution for the lotteries will be as follows:',
  termsPriceDistribution40:
    '55% of the total prize pool will go to the first place winner',
  termsPriceDistribution25:
    '25% of the total prize pool will go to the second place winner',
  termsPriceDistribution16:
    '20% of the total prize pool will go towards ocean and coastline cleanup initiatives',
  termsPriceDistribution4:
    '4% of the total prize pool will go towards development and maintenance of the platform',
  termsPurchase:
    'To participate in the lottery, you must purchase a ticket using Ethereum. The price of a ticket will be determined by Ethalotto and may vary through time. All ticket purchases are final and non-refundable.',
  termsPurchaseSecond:
    'By purchasing a ticket and participating in the lottery, you acknowledge that you are aware of and agree to the terms and conditions set forth in this document.',
  termsWinners:
    'The winners of the lottery will be determined through a random selection process, saving all tickets and winners in the Ethereum blockchain. The selection of the winners will be final and non-negotiable.',
  termsClaiming:
    'The winners will receive the price in the same address used to buy the ticket withing 48h after a lottery is settled.',
  termsTaxes:
    'All winners are responsible for paying any applicable taxes on their prizes. Ethalotto is not responsible for paying any taxes on behalf of the winners.',
  termsLiability:
    'Ethalotto shall not be liable for any damages or losses of any kind arising from or in connection with the lottery or the use of the prizes.',
  termsChangeTerms:
    'Ethalotto reserves the right to change the terms and conditions of the lottery at any time without notice. It is the responsibility of the participants to stay informed of any changes to the terms and conditions.',
  termsLaw:
    'These terms and conditions shall be governed by and construed in accordance with the laws of Panamá and any disputes shall be subject to the exclusive jurisdiction of the courts of Panamá',
  termsLawSecond:
    'By participating in the lottery, you acknowledge that you have read and agree to be bound by these terms and conditions.',
  altBay:
    'A disheartening view of Panamas bay area and its accumulation of trash',
  altLogo: 'Ethalotto Logo',
  altTurtle: 'This turtle species is among the most abundant in Panamá',
  ariaDiscord: 'Join our Discord Community',
  ariaIg: 'Join our Instagram Community',
}
