<template>
  <section class="impact-section">
    <h2 class="section-header">How We Make an Impact</h2>
    <div class="impact-list">
      <div
        class="impact-item"
        v-for="(item, index) in impactItems"
        :key="index"
      >
        <img :src="item.image" :alt="item.title" class="impact-image" />
        <div class="impact-text">
          <h3 class="impact-title">{{ item.title }}</h3>
          <p class="impact-description">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      impactItems: [
        {
          title: 'Supporting Local NGOs',
          description:
            'A portion of the funds is directed to local NGOs that share our mission of environmental conservation and sustainability. Your contributions help these organizations continue their vital work.',
          image: require('@/assets/support.png'),
        },
        {
          title: 'Environmental Restoration Events',
          description:
            'We organize and fund events dedicated to both reforestation and garbage collection. These initiatives bring together communities to plant trees, restore natural habitats, and clean up our coastlines and other natural areas. By tackling both reforestation and waste removal, we help protect and rejuvenate the environment.',
          image: require('@/assets/events.png'),
        },
        {
          title: 'Tree Planting Initiatives',
          description:
            'Whenever possible, we plant trees directly. This not only helps in reforestation efforts but also contributes to carbon offset and biodiversity.',
          image: require('@/assets/reforest.png'),
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.impact-section {
  padding: 22px 0px 88px;
  background-color: #f9f9f9;
}

.section-header {
  text-align: center;
  margin-bottom: 39px;
  color: $secondary;
  font-size: 55px;
  font-family: 'Patua One', cursive;
}

.impact-list {
  display: flex;
  justify-content: center;
}

.impact-item {
  align-items: center;
  margin-bottom: 1.5rem;
  max-width: fit-content;
  margin: 0 33px;
}

.impact-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 1rem;
}

.impact-text {
  flex: 1;
  max-width: 411px;
}

.impact-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #555;
}

.impact-description {
  font-size: 1rem;
  color: #666;
}
@media (max-width: 1089px) {
  .impact-list {
    display: block;
  }
  .section-header {
    font-size: 33px;
  }
}
</style>
